import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import "./blog-card.component.scss";
import { navigate } from "gatsby";
import { getShortSpanishDate } from "../../functions/date.function";

export const BlogCardComponent = ({ cardData }: { cardData: any }) => {

  return (
    <div className="blog-card"
      key={cardData.id}
      onClick={()=>{navigate("/" + cardData.layout + "/" +  cardData.slug )}}
    >
      <GatsbyImage className="blog-card-thumbnail" image={cardData.thumbnail.childImageSharp.gatsbyImageData} alt={""} />
        
        <div className="blog-card-container">
            <div className="blog-card-title">
                <h3 className="blog-title"><b>{cardData.title}</b></h3>
            </div>
            <label className="blog-card-date">{getShortSpanishDate(cardData.date)}</label>
            <div className="blog-card-description">
                <p>{cardData.description}</p> 
            </div>
        </div>
    </div>
  );
};
