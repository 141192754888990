import React from 'react';
import { Link, navigate } from 'gatsby'
import { BlogCardComponent } from '../components/blog-card/blog-card.component';
import { BlogLayout } from '../layouts/blog/blog.layout';
import { SeoComponent } from '../components/seo/seo.component';

export const Head = ({ pageContext }: { pageContext: any }) => {
  const { page, siteMetadata } = pageContext;
  const blogMetaData = {
    title: "Blog - Quepler Technology",
    siteUrl: siteMetadata.siteUrl,
    url: siteMetadata.siteUrl + "/blog/" + page + "/",
    author: siteMetadata.author,
    lang: siteMetadata.lang,
    charSet: siteMetadata.charSet,
    image: siteMetadata.image,
    description: "Blog - Quepler Technology | Quito - Ecuador, sitio web donde encontrarás los mejores blogs de tecnología, electrónica y más con Quepler Technology.",
    twitterUsername: siteMetadata.twitterUsername,
    menuLinks: siteMetadata.menuLinks
  }

  return (
    <>
      <meta name="resource-type" content="Blog"></meta>
      <SeoComponent {...blogMetaData}/>
   </>
 );
} 

const BlogListTemplate = ({ pageContext }: { pageContext: any }) => {
  const { groupedPosts, group, page, siteMetadata } = pageContext;

  return (
    <BlogLayout 
      siteMetaData={siteMetadata}
      path="blog"
    >
      <div className="blogs-list">
        <h1 className="blogs-list-title">Bienvenido al blog de<br />QUEPLER</h1>
        <p className="blogs-list-subtitle">Encuentra los mejores blogs de tecnología, electrónica y más con Quepler.</p>
        <div className="blogs-list-cards ">
          {
            group.map(
              ({ node }: { node: any }) => {
                const { title, description, date, thumbnail, layout } = node.frontmatter;
                const { slug, id } = node;

                return (
                  <BlogCardComponent key={"card-" + node.id} cardData={{ title, layout, description, date, thumbnail, slug, id }} />
                )
              }
            )
          }
        </div>
        <h3 className='blogs-list-subtitle-footer'>PÁGINAS</h3>
        <div className='page-numbers-container'>
          {
            (page > 1) && <button
              className='pagination-button'
              onClick={() => {
                navigate("/blog/" + (page - 1))
              }}
            ><b>{"<"}</b></button>
          }
          <div className='numbers-container'>
            {groupedPosts.map((x: any, index: number) => {
              const currentPage = index + 1;
              const className = currentPage == page ? "number-activated" : "";

              if (groupedPosts.length >= 5) {
                if (
                  index == 0 ||
                  index == 1 ||
                  index == groupedPosts.length - 1 ||
                  index == groupedPosts.length - 2
                ) {
                  return (
                    <div key={index} className='page-number'>
                      <Link
                        className={"number " + className}
                        to={`/blog/${currentPage}`}
                      >
                        {index + 1}
                      </Link>
                      {' '}
                    </div>
                  );
                } else {
                  if (currentPage == page) {
                    return (
                      <>
                        <div key={index} className='page-number'>
                          <label className="number">...</label>
                          {' '}
                        </div>
                        <div key={index} className='page-number'>
                          <Link
                            className={"number " + className}
                            to={`/blog/${currentPage}`}
                          >
                            {index + 1}
                          </Link>
                          {' '}
                        </div>
                      </>
                    );
                  }

                  if (index == Math.floor(groupedPosts.length / 2)) {
                    return (<div key={index} className='page-number'>
                      <label className="number">...</label>
                      {' '}
                    </div>);
                  } else {
                    return (<></>);
                  }
                }
                
              } else {
                return (
                  <div key={index} className='page-number'>
                    <Link
                      className={"number " + className}
                      to={`/blog/${currentPage}`}
                    >
                      {index + 1}
                    </Link>
                    {' '}
                  </div>
                )
              }
            })}
          </div>
          {
            (page < groupedPosts.length) && <button
              className='pagination-button'
              onClick={() => {
                navigate("/blog/" + (page + 1))
              }}
            ><b>{">"}</b></button>
          }

        </div>
      </div>
    </BlogLayout>
  )
}

export default BlogListTemplate;
