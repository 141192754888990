export const getSpanishDate = (_date: Date) => {
    // Creamos array con los meses del año
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    // Creamos array con los días de la semana
    const weekdays = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
    // Creamos el objeto fecha instanciándolo con la clase Date
    const date = new Date(_date);
    // Construimos el formato de salida
    return date.getDate() + ' de ' + months[date.getMonth()] + ', ' + date.getUTCFullYear();
}

export const getShortSpanishDate = (_date: Date) => {
    // Creamos array con los meses del año
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    // Creamos array con los días de la semana
    const date = new Date(_date);
    // Construimos el formato de salida
    return  months[date.getMonth()] + ', ' + date.getDate() + ' del ' + date.getUTCFullYear();
}

export const getNumbersDate = (_date: Date) => {
    const date = new Date(_date);
    return  date.getMonth() + '/' + date.getDate() + '/' + date.getUTCFullYear();
}