import React from 'react';
// import { Helmet } from 'react-helmet';
import { SiteMetaDataInterface } from '../../interfaces/site.metadata.interface';

export const SeoComponent = (siteMetaData: SiteMetaDataInterface ) => {
  return (
    <>
      
      <meta charSet={siteMetaData.charSet} />
      <title>{siteMetaData.title}</title>
      <meta name="title" content={siteMetaData.title}></meta>
      {/* // <title>Quepler Technology | Desarrollo, Instalación, Mantenimiento y Reparación de Tecnología Eléctrica y Electrónica</title> */}
      {/* <title>{siteMetaData.title}</title>
      <meta
          name="description"
          property="og:description"
          content={siteMetaData.description}
        />
      
      
      <meta name="keywords" content="Quepler, Mantenimineto, Industrial, Tecnología, Lavadoras, Reparación, Redes, Electrónica, PCB"></meta>
      <meta property="og:site_name" content={siteMetaData.title} />
      <meta property="og:url" content={siteMetaData.url} />
      <meta property="og:title" content={siteMetaData.title} />
      <meta property="og:image" content={siteMetaData.image} />
      <meta property="og:image:width" content="828" />
      <meta property="og:image:height" content="450" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={siteMetaData.title} />
      <meta name="twitter:description" content={siteMetaData.description} />
      <meta name="twitter:image" content={siteMetaData.image} />
      <meta name="copyright" content="© 2022 Onixdev"></meta> */}


      {/* <!-- HTML Meta Tags --> */}
      <meta name="description" content={siteMetaData.description} />
      <meta name="keywords" content="Quepler,Quepler Technology,Mantenimiento,Industrial,Tecnología,Lavadoras,Reparación,Redes,Electrónica,PCB,Quito,Ecuador"></meta>
      <meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
      <meta http-equiv="Pragma" content="no-cache" />
      <meta http-equiv="Expires" content="0" />
      
      {/* <!-- Google / Search Engine Tags --> */}
      <meta itemProp="name" content={siteMetaData.title} />
      {/* <meta itemProp="description" content={siteMetaData.description} /> */}
      <meta itemProp="image" content={siteMetaData.image} />
      
      {/* <!-- Facebook Meta Tags --> */}
      <meta property="og:url" content={siteMetaData.url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={siteMetaData.title} />
      <meta property="og:description" content={siteMetaData.description} />
      <meta property="og:image" content={siteMetaData.image} />
      {/* <meta property="og:image:width" content="828" />
      <meta property="og:image:height" content="450" /> */}
      
      {/* <!-- Twitter Meta Tags --> */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={siteMetaData.title} />
      <meta name="twitter:description" content={siteMetaData.description} />
      <meta name="twitter:image" content={siteMetaData.image} />

      <meta name="copyright" content="Copyright ©2022 Onixdev"></meta>
      <meta name="revisit-after" content="1 days"></meta>
      {/* <meta name="robots" content="index, follow" /> */}
      {/* <meta name="robots" content="max-snippet:20, max-image-preview:large"></meta> */}
      {/* BING */}
      <meta name="msnbot" content={siteMetaData.description}></meta>
    </>
  )
}
