import React from 'react';
import FooterComponent from '../../components/footer/footer.component';
import { SeoComponent } from '../../components/seo/seo.component';
import { HeaderComponent } from '../../components/header/header.component';
import { WhatsappButtonComponent } from '../../components/whatsapp-button/whatsapp-button.component';
import "./blog.layout.scss";
import "../../templates/blog-post.template.scss";
import "../../templates/blog-list.template.scss";

export const BlogLayout = (props: any) => {
  
  return (
    <>
      {/* <SeoComponent {...props.blogMetaData} /> */}
      <HeaderComponent {...props.siteMetaData} />

      <main className='blog-layout'>
        {props.children}
      </main>

      <WhatsappButtonComponent />
      <FooterComponent siteMetaData={props.siteMetaData} />
    </>
  )
}